import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./Siteauditstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import { interpolateRgb } from "d3-interpolate";
import Box from "@mui/material/Box";
import Select from "react-dropdown-select";
import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useSelector, useDispatch } from "react-redux";
import { GetCompetitorJobIds as GetCompetitorInJobIds } from "../../../Store/CompetitorInJobId";
import Loader from "../CommonLoader";
import { useNavigate } from 'react-router-dom';

export default function Pagehealthlists(props) {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const [jobidvalData, setjobidvalData] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl,SetDoaminSectionUrl]=useState([])
  const [Domainsectiondata,SetDomainsectiondata]=useState([])
  const [autoheightval,SetAutoheightval]=useState(true)
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
      id:
      'Select Date',
  });

  const ApiToken = esodata.apiauthkey.value;


  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
        if (doaminsectiondatavalue.data.domain_sections) {
        
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) {}
    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
  if(completevalues?.data?.jobs.length>0){
    setcompletedate(completevalues?.data?.jobs);
    SetdEngineoption(completevalues?.data?.jobs[0])

  }
    
        
    }
  } 
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}pagehealth-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const arr = [];
  useEffect(() => {
    CloseModalTrigger();
    callUserdataApi();
    LoadAddFormFilterOptions()
    dispatch(GetCompetitorInJobIds(esodata.domaindata.value.header_selected));
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "#",
      description: "Index of the row",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "url",
      headerName: "Url",
      description: "The URLs added under URL management",
      width: 900,
      sortable: true,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        // let firstEightLetters = cardData.row.url.substring(0, 100);
        // let lastThreeLetters = cardData.row.url.slice(-5);

        return (
          <>
            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a target="_blank" href={cardData.row.url}>
                  {cardData.row.url}
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "",
      headerName: "View Report",

      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.url_id}/` + `${jobidvalData}`}>
            <button className="buttonView">
              View
              <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              />
            </button>
          </a>
        );
      },
    },
  ];
  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "",domainsecdata="") => {
    // alert("jobid",)
    if(jobid=="")
      jobid=JobId
    if(domainsecdata=="")
    domainsecdata=Domainsectiondata;
    
    setCardData([])
    setLoading(true);
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/pagehealth/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsecdata}&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsecdata}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if(completevalues?.data?.jobs.length>0)
        {
      setcompletedate(completevalues?.data?.jobs);
        }
    }

    const dataval = datavalue?.data?.table?.urls;
    setjobidvalData(datavalue?.data?.job_id);
    setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

    if (dataval && dataval.length>0) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });

      setCardData(newData);
      SetAutoheightval(false)
    }
    else{
      setCardData("");
      SetAutoheightval(true)
  }

    setLoading(false);
  };
 
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  //filter Add start

  const dispatch = useDispatch();

  const [JobId, SetJobId] = useState("");

  const CloseModalTrigger = () => {
    SetJobId("");
    props.setIsOpen(false);
  };

  const JdomainvaldataChange = (value) => {
    SetJobId(value[0].value);
  };
  const SetSectionNameHandle=(passvalue)=>{
    SetDomainsectiondata(passvalue[0]?.value)
    callUserdataApi(JobId,passvalue[0]?.value);
  }

  const FetchCompetitorList = () => {
    var errs = 1;
    let errors = {};

    if (JobId === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }

    if (JobId !== "") {
      callUserdataApi(JobId,Domainsectiondata);
      // SetJobId("");
      props.setIsOpen(false);
    }
  };

  //filter add end
  return (
    <>
    {SectionOptions?.length > 0 &&
        <div
          style={{
            width: "calc(100% - 40px)",
            marginRight: "40px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="listdropdown_drop_site">
            <div>
              <span className="listdropdown_titl">Domain Section</span>

              <Select
                className="drop-organicsearch"
                name="name"
                isClearable
                onChange={SetSectionNameHandle}
                options={SectionOptions}
                isSearchable="true"
                placeholder="Home Page"
              />

            </div>
          </div>
        </div>
                  }
                  <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
      <div
        className="data_table_page page_health_table"
        style={{ width: "100%" }}
      >
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
            className="data_table"
            getRowId={(row) => row.url_id}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            rows={rows}
            columns={columns}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "&, [class^=MuiDataGrid]": { border: "none" },
              ".MuiDataGrid-virtualScroller": {
                height: "auto !important",
                overflowY: "auto",
              },
              "& .MuiDataGrid-cell:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                border: "none",
                zIndex: 999,
              },
            }}
            autoHeight={autoheightval?true:false}      
            getRowHeight={() => "auto"}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            loading={loading}
          />
        </div>
      </div>
      </div>
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Choose The Date"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div className="col">
                <div>
                  <span>Completed Date</span>
                  <Select
                    options={completedate}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => setSelectval(values)}
                  />
                </div>
              </div>

              <div className="col">
                <span className="text-error">{validation.dateerror}</span>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow}>
            Submit
          </button>
          <button onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div>
                <div className="">
                  <div>
                    <span> Completed Date</span>
                    <Select
                    values={[dEngineoption]}
                    options={completedate}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => {SetJobId(values[0]?.id)
                      SetdEngineoption({
                        completed_at:
                          values[0]?.completed_at,
                          id:
                          values[0]?.id,
                      });                    
                    }}
                
                    />
                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="run_rpt_btn rn_rpt_btn"
            onClick={FetchCompetitorList}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={CloseModalTrigger}>
            close
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </>
  );
}
