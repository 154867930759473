import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./codeauditstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
export default function CodeauditDetail() {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [jobidData, setjobidData] = useState("");
  const [domainsecid, setDomainsecid] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  let querystring = useParams();
  let code_audit_id = querystring.id;
  let job_audit_id = querystring.jobid;

  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const navigate = useNavigate();
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  useEffect(() => {
    callUserdataApi();
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
   
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}technical/v2/sourcecode_detail_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${job_audit_id}&id=${code_audit_id}`,{
      
      // `${process.env.REACT_APP_API_URL}technical/sourcecode_detailtable/${code_audit_id}/`,{
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      setLoading(false);
      if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    const dataval = datavalue?.data;
    setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
    setCardData(dataval);
  };
  const pdfdown = async () =>{ 

    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/sourcecode_detail_table/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${code_audit_id}&job_id=${job_audit_id}&formats=pdf&domain_section_id=${domainsecid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                
                var filedata=await Commonfun("SourceCodeDetails","pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }
  const csvdown = async () =>{ 
    setLoading(true);
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/sourcecode_detail_table/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${code_audit_id}&job_id=${job_audit_id}&formats=csv&domain_section_id=${domainsecid}`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("SourceCodeDetails","csv", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
            <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    <div className="cnt_audit_outer_wrap src-detail">
      <div className="cnt_hdr_top_wrap">
        <div className="box_wrap schema_anaysis_outer_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2>Source Code Detailed Report For</h2>
            <p><b>{cardData?.url}</b></p>
            <p>as of {jobDate}</p>
           
          </div>
          <div className="cnt_hdr_top_rgt_wrap ">
          <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon" onClick={csvdown} />
          </div>
        </div>
      </div>
      <div className="cnt_hdr_content">
        <div className="box_wrap">
          <div>
            <h1> </h1>
            <p></p>
          </div>
          <div className="cnt_hdr_content_box_outer ">
            <div className="schema_missing_waring_wrap fatal_errore">
            <h4 className="h_ferrs" style={{ color: "#000000" }}>Fatal Errors</h4>
            <p>{cardData?.fatal_error}</p>
          </div>
          <div className="schema_missing_waring_wrap err_inner">
            <h4 className="h_errorss" style={{ color: "#000000" }}>Errors</h4>
            <p>{cardData?.error}</p>
          </div>
          <div className="schema_missing_waring_wrap warning_erre" >
            <h4 className="h_warns" style={{ color: "#000000" }}>Warnings</h4>
            <p>{cardData?.warning}</p>
          </div>
          </div>
        </div>
      </div>

      <div className="cnt_hdr_content_table">
        <div className="box_wrap">
          <div
            className="post__content"
            dangerouslySetInnerHTML={{ __html: cardData?.html_data }}
          ></div>
        </div>
      </div>
    </div>
    <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
