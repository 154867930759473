import React, { useState, useEffect ,useContext} from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./linkcheckerstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import Ranklinksummary from "./Ranklinksummary";
import RankingComparison from "./RankingComparision";
import RankingVisibility from "./RankingVisibility";
import RankingKeywordTrend from "./RankingKeywordTrend";
import { Outlet, Link } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom"
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
export default function Rankingsummarylists(props) {

  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [campaign, Setcampaign] = useState("");

  const [campaignoption, Setcampaignoption] = useState([]);
  const [dcampaignoption, Setdcampaignoption] = useState([]);
  const [dselectjobid,setdselectjobid]=useState([])
  const [dSearchEngine,SetdSearchEngine]=useState([])
  const [dCountryHandle,SetdCountryHandle]=useState([])
  const [dStateHandle,SetdStateHandle]=useState([])
  const [dCity,SetdCity]=useState([])
 

  const [ErrorMsg, SetErrorMsg] = useState("");
  const [selectjobid, setselectjobid] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [System,SetSystem]=useState('desktop');
  const [Device,SetDevice]=useState('')

  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const [initialDateval, SetInitialDateval] = useState([]); 
  const [autoheightval,SetAutoheightval]=useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();


  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const navigate = useNavigate()

  const [CurrentMenu,SetCurrentMenu]=useState('ranked_keywords')
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const SwitchMenu=(e)=>{

    const Id=e.target.getAttribute('data-id')
    // SetRankCampaign('')
    // SetRankJob('')
    // SetRankEngine('')
    // SetRankCountry('')
    // SetRankState('')
    // SetRankCity('')
    // SetRankSystem('')
    // SetRankDevice('')
    SetCurrentMenu(Id)
    faqdatafun(Id)
  }
  const SetCityHandle = async (value) => {
    SetCity(value.value);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const SetCountryHandle = async (value) => {
    SetStateoptions([]);
    SetdStateHandle([])
    SetState()
    SetCity()
    if (value) {
      SetCountry(value.value);
      var CityPass='';
      if(City.value)
      {
        CityPass=City.value
      }
      try {
        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${value.value}&filter_type=state`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if (statedatavalue?.data) {
          if (statedatavalue?.data?.states) {
         
            var Sections = [];
            console.log(statedatavalue?.data?.states)
            statedatavalue.data.states.forEach((element) => {
              Sections.push({
                label: element?.search_engine_locale__state__state_name,
                value: element?.search_engine_locale__state__id,
              });
            });
            console.log(Sections)
            SetStateoptions(Sections);
            SetdStateHandle(Sections[0])
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    SetdCity([])
    console.log("statevalue",value)
    if (value) {
      if(value?.value  === undefined){
        value.value=0;
              }
      console.log("statevalued",value.length)
      SetState(value);
      var CountryPass='';
      if(Country.value)
      {
        CountryPass=Country.value
      }

      try{

        const citydatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${CountryPass}&state_id=${value.value}&filter_type=city`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
  
        if (citydatavalue?.data) {
          if (citydatavalue?.data?.cities) {
            var Sections = [];
            citydatavalue?.data?.cities.forEach((element) => {
              Sections.push({
                label: element?.search_engine_locale__city__city_name,
                value: element?.search_engine_locale__city__id,
              });
            });
            SetCityOptions(Sections);
            SetdCity(Sections[0])
          }
        }

      }catch(err)
      {
        console.log(err)
      }

    
    }
  };

  const [FilterButtonTriggered,SetFilterButtonTriggered]=useState(false)

  const finalreportNow = async () => {
    
    setLoading(true);
    SetErrorMsg("");
    if (
      selectjobid !== "" ||
      campaign !== "" ||
      SearchEngine !== "" ||
      Country !== "" ||
      State.value !== "" ||
      City.value !== "" ||
      System!=='' || 
      Device!==''

    ) {


      SetRankCampaign(campaign)
  
      SetRankJob(selectjobid)
      SetRankEngine(SearchEngine)
      SetRankCountry(Country)

      if(State?.value)
      {
        SetRankState(State?.value)
      }
      else{
        SetRankState(0)
      }
      if(City?.value)
      {
        SetRankCity(City?.value)
      }
      else{
        SetRankCity(0)
      }
    

      SetRankSystem(System)
      SetRankDevice(Device)
      SetFilterButtonTriggered(true)
    
       
    } else {
      setLoading(false);
      return SetErrorMsg("Please Select the All Fields !");
    }
    closeModal();
    setLoading(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });


  const [RankCampaign,SetRankCampaign]=useState('')
  const [RankJob,SetRankJob]=useState('')
  const [RankEngine,SetRankEngine]=useState('')
  const [RankCountry,SetRankCountry]=useState('')
  const [RankState,SetRankState]=useState('')
  const [RankCity,SetRankCity]=useState('')
  const [RankSystem,SetRankSystem]=useState('')
  const [RankDevice,SetRankDevice]=useState('')
  const [RankTypeval,SetRankTypeval]=useState('')




 


  const LoadAddFormFilterOptions = async (jobid = "") => {
    setjobidData([]);

    SetCountryoptions([]);
    SetEngineoptions([]);
    Setcampaignoption([])
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=campaign`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (campaigndatavalue?.data?.campaigns) {
        if (campaigndatavalue?.data?.campaigns) {
          var campaignSections = [];
          campaigndatavalue?.data?.campaigns.forEach((element) => {
            campaignSections.push({ label: element?.category__name, value: element?.category__id });
          });
          Setcampaignoption(campaignSections);
          Setdcampaignoption(campaignSections[0])
        }
      }
    } catch (err) {}
    try {
      const jobdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=rankings_summary`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (jobdatavalue?.data?.jobs) {
        if (jobdatavalue?.data?.jobs) {
          var jobSections = [];
          jobdatavalue?.data?.jobs.forEach((element) => {
            jobSections.push({ label: element?.completed_at, value: element?.id });
          });
          if(jobSections.length>0)
          setdselectjobid(jobSections[0])
          setjobidData(jobSections);
        }
      }
    } catch (err) {}
    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=search_engine`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (enginedatavalue?.data?.search_engines) {
        if (enginedatavalue?.data?.search_engines) {
          var Sections = [];
          enginedatavalue?.data?.search_engines.forEach((element) => {
            Sections.push({ label: element?.search_engine_locale__search_engine__name, value: element?.search_engine_locale__search_engine__id });
          });
          SetEngineoptions(Sections);
          SetdSearchEngine(Sections[0])
        }
      }
    } catch (err) {}
    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&state_id=${State}&city_id=${City}&filter_type=country`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data?.countries) {
        if (countrydatavalue?.data?.countries) {
          var Sections = [];
          console.log("country",countrydatavalue?.data?.countries)
          countrydatavalue?.data?.countries.forEach((element) => {
            
            Sections.push({ label: element?.search_engine_locale__country__name, value: element?.search_engine_locale__country__id });
          });
          SetCountryoptions(Sections);
          SetdCountryHandle(Sections[0])
          SetdStateHandle(Sections[0])
          SetdCity(Sections[0])
        }
      }
    } catch (err) {}



  };

  const arr = [];

  useEffect(() => {
    const APITOKEN=localStorage.getItem('header_selected');

   
    LoadAddFormFilterOptions()
    faqdatafun();
  }, []);
  useEffect(() =>{
    console.log("props",RankTypeval)

  },[RankTypeval])
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
     ||(event.target.classList[0] === 'run_rpt_btn') ||(event.target.classList[0] === 'urlcheck') 
     ||(event.target.classList[0] === 'link_cnt_hdr_blw_wrap') ||(event.target.classList[0] === 'MuiDataGrid-columnHeaderTitle') 
     ||(event.target.classList[0] === 'cols') ||(event.target.classList[0] === 'card_num_class') 
     ||(event.target.classList[0] === 'content_col') || (event.target.classList[0] ==='link_rn_rpt_btn') 
     || (event.target.classList[0] ==='css-b5h07o') || (event.target.classList[0] ==='site_regress_chart_info') 
      || (event.target.classList[0] ==='linechartdesign')
    ) {
      setfaqData(2)
      setToggle(false)   
    }
});
  const faqdatafun = async(menuval="") => {
   if(menuval==='ranked_keywords'|| menuval==='')
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=4`
  else if( menuval==='comparison')
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=6`
  else if(menuval==='keyword_trend')
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=7`
  else if(menuval==='visibility_score')
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=5`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
       console.log("kannan",doaminsectiondatavalue?.data?.faqs)
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
 
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  function openModal() {


    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  // const ResetFilter=()=>{

  //   SetRankCampaign('')
  //   SetRankJob('')
  //   SetRankEngine('')
  //   SetRankCountry('')
  //   SetRankState('')
  //   SetRankCity('')
  //   SetRankSystem('')
  //   SetRankDevice('')

  //  Setdcampaignoption('');
  //  setdselectjobid('')
  //  SetdSearchEngine('')
  //  SetdCountryHandle('')
  //  SetdStateHandle('')
  //  SetdCity('')
  //  SetSystem('')
  //  SetDevice('')


  // }

  const [RunReportPopShow,SetRunReportPopShow]=useState(false)
  const [RunReportType,SetRunReportType]=useState('rankings_summary')
  const pdfdown = async () =>{ 
    setLoading(true);
    if(CurrentMenu==='ranked_keywords'){
      if(RankTypeval=="")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=`;
      if(RankTypeval=="ranked_keywords")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=ranked_keywords`;
      if(RankTypeval=="first_page_rank")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=first_page_rank`;

    }
    else if(CurrentMenu==='visibility_score'){
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_kpi/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_section=visibility_score`;
    }
    else if(CurrentMenu==='keyword_trend'){
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_kpi/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_section=keyword_trend`;
    }
    if(CurrentMenu==='comparison'){
      if(RankTypeval=="ranked_keywords")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=ranked_keywords`;
      if(RankTypeval=="gained_keyword_data")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=gained_keyword_data`;
      if(RankTypeval=="dropped_keyword_data")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=dropped_keyword_data`;
      if(RankTypeval=="lost_keyword_data")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=lost_keyword_data`;

    }
  
    
    try{
    const response = await fetch(rankBaseurl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                
                var filedata=await Commonfun("RankingSummary","pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
               // link.download = 'AuditSummaryDetail.pdf';

                // Trigger a click on the anchor element to start the download
                link.click();
  
  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              // toast.error("No Data", {
              //   position: "top-center",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "light",
              //   });
              //   console.error('Failed to fetch PDF:', response.status, response.statusText);
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  
  }
  const csvdown = async () =>{ 
    setLoading(true);
    if(CurrentMenu==='ranked_keywords'){
      if(RankTypeval=="")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=`;
      if(RankTypeval=="ranked_keywords")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=ranked_keywords`;
      if(RankTypeval=="first_page_rank")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=first_page_rank`;
    }
    else if(CurrentMenu==='visibility_score'){
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_section=visibility_score`;
    }
    else if(CurrentMenu==='keyword_trend'){
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_section=keyword_trend`;
    }
    if(CurrentMenu==='comparison'){
      if(RankTypeval=="ranked_keywords")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=ranked_keywords`;
      if(RankTypeval=="gained_keyword_data")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=gained_keyword_data`;
      if(RankTypeval=="dropped_keyword_data")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=dropped_keyword_data`;
      if(RankTypeval=="lost_keyword_data")
      var rankBaseurl=`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=lost_keyword_data`;

    }
  
    
    try{
    const response = await fetch(rankBaseurl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("RankingSummary","csv", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
               // link.download = 'AuditSummaryDetail.pdf';

                // Trigger a click on the anchor element to start the download
                link.click();
  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              // toast.error("No Data", {
              //   position: "top-center",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "light",
              //   });
              //   console.error('Failed to fetch PDF:', response.status, response.statusText);
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  
  }
 

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
       <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
          <h2>Ranking Summary Report</h2>
          <p>as of {jobDate}</p>
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
           <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
           <img src={csvicon} alt="csv icon"  onClick={csvdown}/>
          </div>
          
        </div>
      </div>
      
      <div className="link_cnt_hdr_blw_wrap">

     <button data-id="ranked_keywords" className={CurrentMenu==='ranked_keywords' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu}>
      Rank Summary
        </button>
 
      <button data-id="visibility_score" className={CurrentMenu==='visibility_score' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu}>
          Visibility Score
        </button>
       <button  data-id="comparison" className={CurrentMenu==='comparison' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu}>
          Comparison
        </button>
       <button data-id="keyword_trend" className={CurrentMenu==='keyword_trend' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu}>
          Keyword Trend 
        </button>
      </div>


      <div className="site_blw_wrap">
        <div className="cnt_hdr_blw_wrap">
          {/* <button className="run_rpt_btn rn_rpt_btn" onClick={()=>{SetRunReportPopShow(true)}}>
            Run Report Now
          </button> */}
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
        </div>
      </div>
     
  
 
 {
  CurrentMenu==='ranked_keywords' ?      <Ranklinksummary modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice}  SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} RankTypeval={RankTypeval} SetRankTypeval={SetRankTypeval} /> : null
 }

{
  CurrentMenu==='comparison' ?      <RankingComparison modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice}  SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} RankTypeval={RankTypeval} SetRankTypeval={SetRankTypeval}  /> : null
 }

{
  CurrentMenu==='visibility_score' ?      <RankingVisibility modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice}  SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} /> : null
 }

{
  CurrentMenu==='keyword_trend' ?      <RankingKeywordTrend modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice}  SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} /> : null
 }






     





     <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter"
      >
        <DialogTitle>{"Filter"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="error_plagarism_report">{ErrorMsg}</div>
            <div className="four_col">
              <div className="col">
                <label className="form-label">Campaign</label>
                <Select
                  values ={[dcampaignoption]}
                  defaultValue={[dcampaignoption][0]}
                  options={campaignoption}
                  onChange={(e) => {
                    Setcampaign(e[0]?.value);
                    Setdcampaignoption({ label: e[0]?.label, value: e[0]?.value })
                  }}
                  
                />
              </div>
              <div className="col">
                <label className="form-label">Completed Date</label>
                <Select
                  values ={[dselectjobid]}
                  defaultValue={[dselectjobid][0]}
                  options={jobidData}
                  onChange={(e) => {
                    setselectjobid(e[0]?.value);
                    setdselectjobid({ label: e[0]?.label, value: e[0]?.value })
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">Search Engine</label>
                <Select
                  values ={[dSearchEngine]}
                  options={Engineoptions}
                  onChange={(e) => {
                    SetSearchEngine(e[0]?.value);
                    SetdSearchEngine({ label: e[0]?.label, value: e[0]?.value })
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">Country</label>
                <Select
                  values ={[dCountryHandle]}
                  options={Countryoptions}
                  onChange={(e) => {
                    SetCountryHandle(e[0]);
                    SetdCountryHandle({ label: e[0]?.label, value: e[0]?.value })
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">State</label>
                <Select
                  values ={[dStateHandle]}
                  options={Stateoptions}
                  onChange={(e) => {
                    SetStateHandle(e[0]);
                    SetdStateHandle({ label: e[0]?.label, value: e[0]?.value })
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">City</label>
                <Select
                  values ={[dCity]}
                  options={CityOptions}
                  onChange={(e) => {
                    console.log("cityvalue",e[0].value)
                    SetCity(e[0]);
                    // SetCityHandle(e[0]);
                    SetdCity({ label: e[0]?.label, value: e[0]?.value })
                  }}
                />
              </div>

              <div className="col">
                <label className="form-label">System</label>

                <input type="radio" name="system" value={'mobile'} onChange={(e)=>{SetSystem(e.target.value)}}  defaultChecked={System==='mobile' ? true : false}/>Mobile
                <input type="radio" name="system" value={'desktop'} onChange={(e)=>{SetSystem(e.target.value)}} defaultChecked={System==='desktop' ? true : false}/>Desktops
            
              </div>

              {
                 System==='mobile' ?     <div className="col">
                 <label className="form-label">Devices</label>
 
                 <input type="radio" name="devices" value={'android'} defaultChecked={Device==='android' ? true : false} onChange={(e)=>{SetDevice(e.target.value)}} />Android
                 <input type="radio" name="devices" value={'ios'} defaultChecked={Device==='ios' ? true : false}  onChange={(e)=>{SetDevice(e.target.value)}}/>Ios
             
               </div> : null
              }

          
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>


        {/* <button
            className="run_rpt_btn rn_rpt_btn"
            style={{ cursor: "pointer" }}
            onClick={ResetFilter}
          >
            Clear
          </button> */}

          <button
            className="run_rpt_btn rn_rpt_btn"
            onClick={finalreportNow}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button
            className="run_rpt_btn rn_rpt_btn"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
          >
            close
          </button>

        
        </DialogActions>
      </Dialog>

     
     
    
    </div>
    <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType}/>
    <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>

    
  );
}
